import UserProfileDetails from '@/components/Pages/UserProfilePage'
import { getGuestRelatedProps } from '@/helpers'
import { handleOgImage } from '@/helpers/handleOgImage'
import { useGuestLoginFlow } from '@/hooks'
import { wrapper } from '@/store'
import { initializeUserProfilePage } from '@/store/actions/user'
import {
    getUserProfileData,
    getUserProfileDataWithUsername,
} from '@/utils/api-ssr'
import { logOut } from '@/utils/auth'
import { getUpdatedToken } from '@/utils/getUpdatedToken'
import Head from 'next/head'
import { Fragment, useEffect } from 'react'

const Username = ({
    profileData,
    tokenExpiredFromSession,
    guestTokenResponse,
}) => {
    useGuestLoginFlow(guestTokenResponse)

    const user = profileData?.user?.data

    useEffect(() => {
        if (tokenExpiredFromSession) logOut()
    }, [tokenExpiredFromSession])

    return (
        <Fragment>
            <Head>
                <title>
                    {user && user?.displayName} - Noice user profile | Noice
                </title>
                <meta
                    name="description"
                    content={`Noice user profile of ${
                        user && user?.displayName
                    }, enjoy unlimited Podcast, Audiobook, Audioseries & Radio streaming`}
                />
                <meta
                    property="og:title"
                    content={`${
                        user && user?.displayName
                    } - Noice user profile | Noice`}
                />
                <meta
                    property="og:description"
                    content={`Noice user profile of ${
                        user && user?.displayName
                    }, enjoy unlimited Podcast, Audiobook, Audioseries & Radio streaming`}
                />
                {user && (
                    <meta
                        property="og:image"
                        content={handleOgImage(user?.photo || user?.imageMeta)}
                    />
                )}
            </Head>
            <UserProfileDetails userId={user?.id} />
        </Fragment>
    )
}

export const getServerSideProps = wrapper.getServerSideProps(
    (store) =>
        async ({ req, res, query }) => {
            const { token, session, guestTokenResponse } =
                await getUpdatedToken(req, res)

            const { username } = query

            const userData = await getUserProfileDataWithUsername(
                username,
                token
            )
            const userId = userData?.data?.id

            const request = {
                userId,
                page: 1,
                limit: 10,
                includeEntities: `["userActions", "user", "live"]`,
                aggregations: 'listeningTime|followers|followees',
                profileUserId: userId,
            }
            const profileData = await getUserProfileData(request, token)

            if (
                profileData?.message?.includes('token') ||
                profileData?.message?.includes('Token')
            ) {
                return {
                    props: {
                        tokenExpiredFromSession: true,
                    },
                }
            }

            if (profileData?.user?.data)
                store.dispatch(initializeUserProfilePage(profileData))

            return {
                props: {
                    profileData: profileData || {},
                    ...getGuestRelatedProps(session, guestTokenResponse),
                },
            }
        }
)

export default Username
